import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Col, Row, Image } from "react-bootstrap"

import businessImage from "../images/business.jpg"

const EmailPage = () => (
  <Layout>
    <SEO title="Email" />
    <Container style={{ marginTop: "2rem" }}>
      <Row>
        <Col>
          <h1>Premier Email Solutions</h1>
          <h5>Email to get your business online.</h5>
          <p>
            As all businesses rely quite heavily on email, having a
            professional, stable and secure system is very important in
            communicating safely with customers, parents and students.
          </p>
          <p>
            We can set up and configure a secure, enterprise-style email,
            calendar & collaboration without the high cost.
          </p>
          <p>Services include:</p>
          <ul>
            <li>
              Email integrated with shared calendars and scheduling, contacts
              management, tasks, notes, shared and public folders, and instant
              messaging.
            </li>
            <li>
              Web Based email, Mobile email and Microsoft Outlook/Office
              integration.
            </li>
            <li>Email group management for better user/staff organization.</li>
            <li>
              Synchronize messages by direct push to your choice of mobile
              device via Microsoft ActiveSync(R).
            </li>
            <li>
              Spam and phish blocker to mitigate, remove or prohibit unwanted
              emails.
            </li>
          </ul>

          <Image
            style={{ margin: "2rem 0" }}
            rounded
            src={businessImage}
          ></Image>

          <div style={{ margin: "4rem 0" }}></div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default EmailPage
